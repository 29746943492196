.ways-slider {
  position: relative;
  padding: 1rem;
}

.toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.toggle-slider {
  position: relative;
  width: 200px;
  height: 40px;
  background-color: #1E293B;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
}

.toggle-button {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #02A724;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.toggle-button.right {
  transform: translateX(100%);
}

.toggle-labels {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.toggle-labels span {
  color: white;
  font-weight: bold;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.toggle-labels span.active {
  opacity: 1;
}

.slider-content {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}