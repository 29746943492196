.getting-started-container {
  background: linear-gradient(to bottom, #1E293B, #4C1D95);
  padding: 6rem 0;
  color: white;
}

.getting-started-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.main-heading {
  color: white;
  font-size: 3.157rem !important;
  margin-bottom: 5rem;
  text-align: center;
  line-height: 4rem !important;
}

.step-card {
  background-color: white;
  padding: 2rem;
  width: 32%;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out;
}

.step-number {
  color: #000;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: block;
}

.step-title {
  color: #000;
  font-size: 1.777rem !important;
  margin-bottom: 3rem;
  line-height: 1.2 !important;
}

.step-description {
  color: #666;
  font-size: 1.333rem;
  line-height: 1.5;
}


.get-started-button {
  background-color: #02A724 !important;
  color: white !important;
  font-weight: bold;
  height: 56px !important;
  font-size: 1.333rem !important;
  padding: 0 2.5rem !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  transition: background-color 0.3s ease !important;
}

.get-started-button:hover {
  background-color: white !important;
  color: #02A724 !important;
}

.get-started-button{
  cursor: pointer !important; /* !important para asegurarse, con precaución */
}

.get-started-button {
  width: 20px;
  height: 20px;
}


/* Estilos específicos para móvil */
@media screen and (max-width: 768px) {
  .mobile-carousel {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .carousel-content {
    margin-bottom: 1.5rem;
  }

  .step-card {
    width: 100%;
    margin: 0 auto;
  }

  .carousel-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .carousel-arrow {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
  }

  .carousel-arrow:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  .carousel-dots {
    display: flex;
    gap: 0.5rem;
  }

  .carousel-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .carousel-dot.active {
    background: white;
    transform: scale(1.2);
  }

  .step-card {
    padding: 1.5rem;
  }

  .step-title {
    font-size: 1.5rem !important;
    margin-bottom: 1rem;
  }

  .step-description {
    font-size: 1.1rem;
  }
}

/* Ajustes adicionales para pantallas muy pequeñas */
@media screen and (max-width: 380px) {
  .carousel-arrow {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }

  .step-card {
    padding: 1rem;
  }

  .step-title {
    font-size: 1.3rem !important;
  }

  .step-description {
    font-size: 1rem;
  }
}

