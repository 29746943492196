.hero {
  background: #0A2540;
  width: 100%;
}

.hero__inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  display: table;
}

.hero__content {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  width: 77%;
  padding-right: 3rem;
}

.hero__image {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

.hero__title {
  color: white;
  font-size: 4.209em;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.hero__description {
  color: white;
  font-size: 2.369rem;
  line-height: 1.3;
  margin-bottom: 2.5rem;
}

.hero__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.hero__button {
  background-color: #02A724;
  font-size: 1.333rem;
  color: white;
  padding: 0.8rem 2.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero__button:hover {
  background-color: white;
  color: #02A724;
  transform: translateY(-2px);
}

.hero__image img {
  max-width: 80%;
  height: auto;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .hero__title {
    font-size: 3.5em;
  }

  .hero__description {
    font-size: 2rem;
  }

  .hero__button {
    font-size: 1.2rem;
    padding: 0.7rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .hero__inner {
    padding: 2rem 1rem;
  }

  .hero__content {
    width: 100%;
    padding-right: 0;
  }

  .hero__title {
    font-size: 2.8em;
  }

  .hero__description {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 480px) {
  .hero__inner {
    padding: 2rem 1rem;
  }

  .hero__title {
    font-size: 2.2em;
    margin-bottom: 1.5rem;
  }

  .hero__description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .hero__buttons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 1rem;
  }

  .hero__button {
    width: auto;
    min-width: 300px;
    max-width: 80%;
    font-size: 1.1rem;
    padding: 0.7rem 1.5rem;
  }
}

/* Para pantallas muy pequeñas */
@media screen and (max-width: 320px) {
  .hero__title {
    font-size: 1.8em;
  }

  .hero__description {
    font-size: 1rem;
  }

  .hero__button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }
}