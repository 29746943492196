.game-changer-container {
  padding: 6rem 0;
  background-color: #f8f9fa;
}

.game-changer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  gap: 4rem;
  align-items: center;
}

.text-column {
  flex: 1;
  padding-right: 2rem;
}

.main-title-changer {
  font-size: 3.157rem !important;
  color: #2D2D3A;
  margin-bottom: 1.5rem;
  line-height: 1.2 !important;
}

.description-changer {
  color: #666;
  margin-bottom: 2rem;
  line-height: 1;
  font-size: 1.333rem !important;
}

.features-list-changer {
  margin-bottom: 4rem !important;
  margin-top: 2.5rem;
  font-size: 1.333rem;
}

.feature-item {
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.feature-icon {
  color: #8E44AD;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  background-color: #f0e6f7;
  border-radius: 50%;
  position: relative;
}

.feature-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.access-button-changer {
  background-color: #02A724 !important;
  color: white !important;
  padding: 0 2.5rem !important;
  height: 56px !important;
  font-size: 1.333rem !important;
  font-weight: bold !important;
  transition: all 0.3s ease !important;
  border: 2px solid #02A724 !important;
  border-radius: 8px !important;
  cursor: pointer !important;
}

.access-button-changer:hover {
  background-color: white !important;
  color: #02A724 !important;
  transform: translateY(-2px);
}






.charts-column {
  flex: 1;
}

.tablet-frame {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.tablet-frame::before {
  content: '';
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .game-changer-content {
    flex-direction: column;
    gap: 2rem;
  }

  .text-column {
    padding-right: 0;
  }
}