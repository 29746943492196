.container {
    padding: 1rem;  /* Reducido para móvil */
    margin-top: 3rem;  /* Reducido para móvil */
}

.cards-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
}

.card-header {
    height: 128px;
    background-color: #03a725 !important;
    position: relative;
    width: 100%;
    display: block;
}

.verified-badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    color: #4a5568;
    font-weight: 500;
}

.profile-image {
    position: absolute;
    bottom: -40px;
    left: 1.5rem;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid white;
    z-index: 1;
    object-fit: cover;
}

.card-content {
    padding: 3rem 1.5rem 1.5rem;
    position: relative;
}



.expand-button {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.expand-button:hover {
    background-color: #f0f0f0;
}

.expandable-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    opacity: 0;
}

.expandable-content.show {
    max-height: 500px; /* Ajusta según necesites */
    opacity: 1;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.card.expanded {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}



.quote {
    color: #4a5568;
    margin-bottom: 1rem;
    font-style: italic;
    line-height: 1.5;
    min-height: 4.5rem;
    font-size: 1rem;
}

.author-info {
    margin-bottom: 1rem;
}

.author-name {
    font-weight: bold;
    color: #1a202c;
    margin: 0;
    margin-top: 1rem;
    font-size: 1.1rem;
}

.author-position {
    color: #718096;
    margin: 0.25rem 0 0 0;
    font-size: 0.9rem;
}

.stats {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.stat-item {
    color: #4a5568;
    margin: 0;
    font-size: 0.9rem;
}

/* Media Queries mejoradas */
@media (max-width: 1200px) {
    .cards-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        padding: 0 1rem;
    }
    
    .quote {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
        margin-bottom: 3rem;
    }

    .cards-grid {
        grid-template-columns: 1fr; /* Una sola columna */
        gap: 1rem;
        max-width: 400px; /* Opcional: para que las tarjetas no sean demasiado anchas */
        margin: 0 auto;
    }

    .card-content {
        padding: 2rem 1rem 1rem;
    }

    .expand-button {
        position: absolute;
        right: 1rem;
        top: 1.25rem;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
    }

    .expand-button:hover {
        background-color: #f0f0f0;
    }

    .expandable-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        opacity: 0;
    }

    .expandable-content.show {
        max-height: 500px;
        opacity: 1;
        transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
    }

    .card.expanded {
        transform: scale(1.02);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }

    .author-info {
        margin-bottom: 0.5rem;
    }
}

/* Para pantallas muy pequeñas */
@media (max-width: 380px) {
    .container {
        padding: 0.5rem;
    }

    .card-content {
        padding: 2.25rem 1rem 1rem;
    }

    .quote {
        font-size: 0.85rem;
    }
}