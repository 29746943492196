/* tour.css */

/* Tour container styles */
.tour-backdrop {
    opacity: 0.7 !important;
    background-color: #000 !important;
    position: fixed !important;
    z-index: 1100 !important;
}

.tour-step-backdrop {
    position: relative;
    z-index: 1050 !important;
}

/* Popover styles */




/*NUEVOS*/
.tour-backdrop {
    opacity: 0.7;
    background-color: #000;
    z-index: 1100 !important;
}

.tour-step-backdrop {
    z-index: 1101 !important;
}

.tour-step-background {
    z-index: 1101 !important;
}

.tourist.popover {
    z-index: 1102 !important;
}

.tourist.popover .popover-navigation {
    padding: 9px 14px;
    border-top: 1px solid #e5e5e5;
}

.tourist.popover .popover-navigation .btn {
    margin-right: 5px;
}





/*FIN NUEVOS*/



