  .plan-button {
  width: 100% !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
  background-color: #02A724 !important;
  color: white !important;
  transition: all 0.3s ease !important;
  font-weight: bold !important;
  height: 45px !important;
  border: 2px solid #02A724 !important;
  cursor: pointer !important;
  }

  .plan-button:hover {
    background-color: white !important;
    color: #02A724 !important;
    transform: translateY(-2px);
  }
  
  .plan-card {
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 2rem;
    width: 300px;
    transition: transform 0.3s ease;
  }
  
  .plan-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  }
  
  .plan-card ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .plan-card li {
    color: #b0b0b0 !important; /* Aseguramos el color gris */
    margin: 8px 0;
    padding-left: 20px;
    position: relative;
  }
  
  .plan-card li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #02A724 !important; /* Aseguramos el color verde */
  }




  @media (max-width: 768px) {
    .plan-card {
      width: 100%;
      padding: 1.5rem;
      margin: 0.5rem 0;
    }
  
    .plan-card li {
      color: #b0b0b0 !important; /* Mantenemos el color gris en móvil */
      font-size: 0.9rem;
      margin: 6px 0;
    }
  
    .plan-card li:before {
      content: "✓";
      color: #02A724 !important; /* Mantenemos el check verde en móvil */
    }
  
    .plan-button {
      height: 40px !important;
      font-size: 1rem !important;
      margin-top: 1rem !important;
      margin-bottom: 0.5rem !important;
    }
  }