.trusted-companies {
    width: 100%;
    padding: 2rem 1rem;
    overflow-x: hidden; /* Previene scroll horizontal */
}

.trusted-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;
    width: 100%; /* Asegura que el contenedor use el ancho disponible */
}

.stats-container {
    text-align: center;
    margin-bottom: 2rem;
    width: 100%; /* Asegura que el contenedor use el ancho disponible */
    padding: 0 1rem; /* Añade padding para evitar que el texto toque los bordes */
}

.title-trusted {
    font-size: clamp(1.3rem, 4vw, 3.157rem); /* Uso de clamp para tamaño de fuente responsivo */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}

.stats-item {
    white-space: normal; /* Permite que el texto se rompa si es necesario */
}

.subtitle-trusted {
    font-size: clamp(1.1rem, 3vw, 2.369rem);
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    line-height: 1.4;
    padding: 0 1rem;
}

.companies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Grid más flexible */
    gap: 2rem;
    margin-top: 3rem;
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
}

.company-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.company-logo img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

/* Media queries actualizados */
@media (max-width: 1024px) {
    .companies-grid {
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .trusted-container {
        padding: 2rem 0.5rem;
    }

    .stats-container {
        padding: 0 0.5rem;
    }

    .title-trusted {
        flex-direction: column;
        gap: 1rem;
    }

    .stats-separator {
        display: none;
    }
}

@media (max-width: 480px) {
    .trusted-container {
        padding: 1.5rem 0.5rem;
    }

    .companies-grid {
        gap: 1rem;
        padding: 0 0.5rem;
    }

    .company-logo {
        padding: 0.25rem;
    }
}