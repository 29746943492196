.sidebar-header {
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #44a45c;
}

.sidebar-header h2 {
    font-size: 1.25rem;
    color: #2d3748;
    margin-bottom: 0.75rem;
    font-weight: 600;
}

.sidebar-right {
    width: 380px;
    min-width: 380px;
    background-color: #ffff;
    border-left: 1px solid #e0e4e8;
    padding: 1.5rem;
    overflow-y: auto;
    flex-shrink: 0;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.05);
    height: 100vh;
    /* Cambiar min-height a height */
    position: fixed;
    /* Asegurar que el sidebar permanezca fijo */
    right: 0;
    /* Alinear a la derecha */
    top: 0;
    /* Alinear arriba */
}

.sidebar-right h2 {
    font-size: 1.25rem;
    color: #2d3748;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #44a45c;
    font-weight: 600;
}

.functions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 5px;
    /* Agregar padding inferior para evitar que el último elemento se corte */
}

.function-item {
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.function-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border-color: #5bae75;
}

.function-item h3 {
    margin: 0 0 0.75rem 0;
    font-size: 1.1rem;
    color: #44a45c;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.function-icon {
    flex-shrink: 0;
    font-size: 0.9rem;
    line-height: 1.6;
}

.function-title {
    word-break: break-word;
    line-height: 1.4;
}

/* .function-item h3::before {
    content: '⚡';
    font-size: 0.9rem;
    flex-shrink: 0;
} */

.function-item p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #4a5568;
    line-height: 1.5;
}

.function-item p strong {
    color: #2d3748;
    font-weight: 600;
}

.function-item code {
    background-color: #edf2f7;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: 'Consolas', monospace;
    font-size: 0.85rem;
    color: #4a5568;
    border: 1px solid #e2e8f0;
}


.tour-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #5bae75;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    justify-content: center;
}

.tour-button:hover {
    background-color: #399e59;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tour-button:active {
    transform: translateY(0);
}

.tour-icon {
    font-size: 1.1rem;
}





/* Diseño para scrollbar personalizado */
.sidebar-right::-webkit-scrollbar {
    width: 8px;
}

.sidebar-right::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebar-right::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 4px;
}

.sidebar-right::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
}