.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.app-header {
    background-color: #1a73e8;
    color: white;
    padding: 1rem;
    text-align: center;
    flex-shrink: 0;
}

.app-header h1 {
    margin: 0;
    font-size: 1.5rem;
}

.main-content {
    display: flex;
    flex: 1;
    min-height: 0;
    width: 100%;
}


