.title-features {
  text-align: center;
  margin-bottom: 6rem !important;
  font-size: 3.157rem !important;
}

@media (max-width: 768px) {
  .title-features {
      font-size: 2rem !important;
      margin-bottom: 3rem !important;
  }

  .mobile-features {
      position: relative;
      overflow: hidden;
      padding: 0 1rem;
      margin: 0 auto;
  }

  .mobile-features-track {
      display: flex;
      transition: transform 0.5s ease;
  }

  .mobile-feature-slide {
      min-width: 100%;
      padding: 0 1rem;
      box-sizing: border-box;
  }

  .mobile-dots {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1.5rem;
  }

  .mobile-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      border: none;
      padding: 0;
      cursor: pointer;
  }

  .mobile-dot.active {
      background-color: white;
  }

  .sync-banner {
    padding: 2rem !important;
  }

  .sync-banner h2 {
    font-size: 1.8rem !important;
    line-height: 2.2rem !important;
    margin-bottom: 1rem !important;
  }

  .sync-banner p {
      font-size: 1.2rem !important;
  }

  /* Ajustar el tamaño de las estrellas */
  .sync-banner [size="7"] {
      font-size: 2rem !important;
  }

}