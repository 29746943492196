/* Estilos base */
.oldway-container {
    background-color: #ffffff;
    padding: 80px 0;
  }
  
  .oldway-box {
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .oldway-flex-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .oldway-content {
    flex: 1;
    margin-right: 40px;
  }
  
  .oldway-list {
    list-style-type: none;
    padding: 0;
  }
  
  .oldway-list-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .oldway-x-mark {
    margin-right: 16px;
    color: red;
    font-size: 30px;
  }
  
  .oldway-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .oldway-image {
    max-width: 100%;
    height: auto;
  }
  
  /* Estilos existentes */
  .title-oldway {
    font-size: 3.157rem !important;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 800;
    color: #FF4500;
  }

  .title-newway {
    font-size: 3.157rem !important;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 800;
    color: #02A724;
    line-height: 3rem !important;
  }
  
  .subtitle-way {
    color: #666;
    font-weight: bold;
    letter-spacing: 0.04rem;
    margin-bottom: 1rem;
    font-size: 2.369rem;
  }
  
  .points-ways {
    font-weight: 500;
    color: #333;
    font-size: 1.333rem;
  }


  .reverse {
    flex-direction: row-reverse;
  }
  
  .reverse .oldway-content {
    margin-right: 0;
    margin-left: 40px;
  }
  
  /* Para el check verde en NewWay */
  .oldway-x-mark.check {
    color: #02A724;
  }

  
  /* Media Queries para móvil */
  @media (max-width: 768px) {
    .oldway-container {
      padding: 40px 20px;
    }
  
    .oldway-box {
      padding: 20px;
    }
  
    .oldway-flex-container {
      flex-direction: column;
    }
  
    .oldway-content {
      margin-right: 0;
      margin-bottom: 30px;
    }
  
    .title-oldway {
      font-size: 2.5rem !important;
      text-align: center;
    }
  
    .subtitle-way {
      font-size: 1.8rem;
      text-align: center;
    }
  
    .points-ways {
      font-size: 1.1rem;
    }
  
    .oldway-x-mark {
      font-size: 24px;
      margin-right: 12px;
    }
  
    .oldway-list-item {
      margin-bottom: 15px;
    }
  
    .oldway-image-container {
      margin-top: 20px;
    }
  
    .oldway-image {
      max-width: 90%;
    }

    .reverse {
      flex-direction: column;
    }
  
    .reverse .oldway-content {
      margin-left: 0;
    }
  }
  
  /* Media Queries para tablets */
  @media (min-width: 769px) and (max-width: 1024px) {
    .oldway-container {
      padding: 60px 30px;
    }
  
    .title-oldway {
      font-size: 2.8rem !important;
    }
  
    .subtitle-way {
      font-size: 2rem;
    }
  
    .points-ways {
      font-size: 1.2rem;
    }

    .reverse .oldway-content {
      margin-left: 30px;
    }
  }