/* KeyFeatures.css */
.key-features-container {
  padding: 6rem 0;
  background-color: #f8f9fa;
}

.main-title {
  color: #2D2D3A;
  margin-bottom: 4rem !important;
  text-align: center;
  font-size: 3.157rem !important;
}

.features-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;
}

.features-image {
  flex: 1;
}

.dashboard-image {
  width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.dashboard-image:hover {
  transform: translateY(-5px);
}

.features-text {
  flex: 1;
  padding-left: 2rem;
}

.subtitle {
  color: #2D2D3A;
  margin-bottom: 3rem;
  font-size: 2.369rem !important;
}

.description {
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.1;
  font-size: 1.333rem !important;
}

.features-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.feature-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top: 1rem;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.feature-emoji {
  font-size: 1.5rem;
  line-height: 1;
}

.feature-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #2D2D3A;
}

.feature-description {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
}

.access-button-key {
  background-color: #02A724 !important;
  color: white !important;
  font-weight: bold !important;
  height: 56px !important;
  font-size: 1.333rem !important;
  padding: 0 2.5rem !important;
  border: none !important;
  border-radius: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  box-shadow: 0 4px 15px rgba(2, 167, 36, 0.2) !important;
  margin-top: 1rem !important;
}

.access-button-key:hover {
  background-color: #028f1f;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(2, 167, 36, 0.3);
}




/* Ajustes para tablets y dispositivos medianos */
@media (max-width: 1024px) {
  .main-title {
    font-size: 2.5rem !important;
    margin-bottom: 3rem !important;
  }

  .subtitle {
    font-size: 2rem !important;
    margin-bottom: 2rem;
  }

  .description {
    font-size: 1.1rem !important;
  }

  .features-content {
    gap: 2rem;
  }
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .key-features-container {
    padding: 3rem 0;
  }

  .main-title {
    font-size: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .features-content {
    display: flex; /* Cambiamos a flex para control vertical */
    flex-direction: column; /* Organizamos los elementos verticalmente */
    gap: 2rem;
  }

  .features-image {
    order: -1; /* Esto hace que la imagen aparezca primero */
    width: 100%;
    margin-bottom: 1rem;
  }

  .dashboard-image {
    width: 100%;
    max-width: 100%;
  }

  .features-text {
    padding-left: 1;
    width: 90%;
  }

  .subtitle {
    font-size: 1.75rem !important;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .description {
    font-size: 1rem !important;
    text-align: center;
    line-height: 1.4;
  }

  .feature-card {
    padding: 1rem;
    margin-top: 0.75rem;
  }

  .feature-emoji {
    font-size: 1.25rem;
  }

  .feature-title {
    font-size: 1rem;
  }

  .feature-description {
    font-size: 0.9rem;
  }

  .access-button-key {
    height: 48px !important;
    font-size: 1.1rem !important;
    padding: 0 1.5rem !important;
    width: 100% !important;
    margin-top: 1.5rem !important;
  }
}

/* Ajustes para dispositivos móviles muy pequeños */
@media (max-width: 480px) {
  .key-features-container {
    padding: 2rem 0;
  }

  .main-title {
    font-size: 1.75rem !important;
    margin-bottom: 1.5rem !important;
  }

  .subtitle {
    font-size: 1.5rem !important;
  }

  .features-content {
    gap: 1.5rem;
  }

  .dashboard-image {
    border-radius: 12px;
  }

  .feature-card {
    padding: 0.875rem;
  }

  .feature-header {
    gap: 0.5rem;
  }

  .access-button-key {
    height: 44px !important;
    font-size: 1rem !important;
  }
}