.header-main {
  pointer-events: none;
}

.header-main * {
  pointer-events: auto;
}

.button-demo {
    background-color: #02A724 !important;
    color: white !important;
    font-weight: bold;
    height: 40px !important;
    font-size: 1rem !important;
    padding: 0 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    transition: background-color 0.3s ease !important;
    border: 1px solid #02A724 !important;
}
  
.button-demo:hover {
    background-color: white !important;
    color: #02A724 !important;
}

.button-demo{
    cursor: pointer !important; /* !important para asegurarse, con precaución */
}
  
.button-demo svg {
    width: 20px;
    height: 20px;
}



/*Estilo de boton para el chatbot*/
.chat-button {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  padding: 8px 16px !important;
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05)) !important;
  border-radius: 20px !important;
  color: white !important;
  border: 1px solid rgba(255,255,255,0.1) !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}
  
  .chat-button:hover {
    background: linear-gradient(135deg, rgba(255,255,255,0.2), rgba(255,255,255,0.1)) !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  }
  
  .sparkle-icon {
    animation: sparkle 2s infinite;
  }
  
  @keyframes sparkle {
    0% {
      transform: scale(1) rotate(0deg);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.2) rotate(180deg);
      opacity: 1;
    }
    100% {
      transform: scale(1) rotate(360deg);
      opacity: 0.5;
    }
  }
  
  .chat-button::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
      circle,
      rgba(255,255,255,0.1) 0%,
      rgba(255,255,255,0) 70%
    );
    animation: pulse 3s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 0.8;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.5;
    }
  }



  .mobile-menu-button {
    display: none !important;
    background: transparent !important;
    border: none !important;
    padding: 8px !important;
  }
  
  /* Media query para móvil */
  @media (max-width: 768px) {
    .mobile-menu-button {
      display: block !important;
    }
  
    .desktop-menu {
      display: none !important;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #1E293B;
      flex-direction: column !important;
      padding: 1rem;
      gap: 0.5rem !important;
    }
  
    .desktop-menu.mobile-menu-open {
      display: flex !important;
    }
  
    .desktop-menu a, 
    .desktop-menu button {
      width: 100%;
      justify-content: center;
    }
  
    .chat-button {
      display: flex !important;
      align-items: center !important;
      gap: 8px !important;
      padding: 8px 16px !important;
      background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05)) !important;
      border-radius: 20px !important;
      color: white !important;
      border: 1px solid rgba(255,255,255,0.1) !important;
      position: relative !important;
      overflow: hidden !important;
      transition: all 0.3s ease !important;
  }
  
    .button-demo {
      width: 100% !important;
    }
  }
  
  /* Asegurar que el diseño desktop se mantiene por encima de 768px */
  @media (min-width: 769px) {
    .desktop-menu {
      display: flex !important;
      flex-direction: row !important;
    }
  }