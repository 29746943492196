.custom-button-footer,
  .outline-button-footer{
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s !important;
    font-weight: bold;
    padding: 0 2.5rem !important;
    height: 56px !important;
    font-size: 1.333rem !important;
  }
  

  @media (max-width: 768px) {
    .custom-button-footer,
    .outline-button-footer {
      width: 100% !important;
      padding: 0 1.5rem !important;
      height: 48px !important;
      font-size: 1.1rem !important;
      justify-content: center !important;
    }
  }



  .custom-button-footer {
    background-color: #02A724 !important;
    color: white !important;
    padding: 14px 30px;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
  }

  .custom-button-footer svg {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }

  .custom-button-footer:hover {
    background-color: white !important;
    color: #02A724 !important;
  }
  
  .outline-button-footer {
    border-color: white !important;
    color: white !important;
    background-color: #02A724 !important;
  }
  
  .outline-button-footer:hover {
    background-color: white !important;
    color: #02A724 !important;
  }

  .custom-button-footer, .outline-button-footer {
    cursor: pointer !important; /* !important para asegurarse, con precaución */
  }