/* Eliminar scrollbars del body/html */
html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
}

body.tour-active {
    overflow: hidden !important;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
}

/* Estilos básicos del área del spreadsheet */
.spreadsheet-area {
    flex: 1;
    /*max-width: calc(100% - 380px);*/
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: hidden !important;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    z-index: 1;
    transition: max-width 0.3s ease;
    height: 95vh;
}



.spreadsheet-area.with-sidebar {
    max-width: calc(100% - 380px);
}

/* Cuando no hay sidebar */
.spreadsheet-area.full-width {
    max-width: 100%;
}

.spreadsheet-area>div {
    flex: 1;
    overflow: hidden !important;
}

/* Estilos del spreadsheet */
.jexcel_container {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    position: relative;
    z-index: 1;
}

.jexcel_content {
    height: auto !important;
    overflow: auto !important;
    position: relative;
    z-index: 1;
}

.jexcel {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

/* Estilos específicos de las celdas */
.jexcel td.formula {
    background-color: #f8f9fa;
}

.jexcel td.error {
    background-color: #fff3f3;
    color: #dc3545;
}

.jexcel>tbody>tr>td,
.jexcel>tbody>tr>td>input {
    color: black !important;
}

/* Estilos del Tour */
.tour-backdrop {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1050 !important;
    pointer-events: none !important;
    overflow: hidden !important;
}

.tour.popover {
    z-index: 1102 !important;
    max-width: 400px;
    width: max-content;
    font-family: Arial, sans-serif;
    position: fixed;
}

.popover-header {
    background-color: #f8f9fa;
    padding: 10px 14px;
    border-bottom: 1px solid #dee2e6;
    margin: 0;
}

.popover-body {
    padding: 10px 14px;
}

.popover-navigation {
    padding: 10px 14px;
    border-top: 1px solid #dee2e6;
    overflow: hidden;
}

.popover-navigation .btn-group {
    float: left;
}

.popover-navigation [data-role="end"] {
    float: right;
}

.popover-navigation .btn {
    margin: 0 3px;
    padding: 5px 10px;
    font-size: 12px;
}

/* Estilos para elementos resaltados durante el tour */
.tour-step-element {
    position: relative !important;
    z-index: 1051 !important;
    background-color: transparent !important;
    pointer-events: auto !important;
    /* Crear un "agujero" en el backdrop */
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5),
        0 0 0 2px #ffffff,
        0 0 0 4px #007bff !important;
}

.tour-highlight,
.tour-highlight-animation {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.jexcel td.tour-step-element,
.jexcel tbody td.tour-step-element,
.jexcel td.tour-highlighted-cell,
.jexcel tbody td.tour-highlighted-cell {
    position: relative !important;
    z-index: 1051 !important;
    background-color: #ffffff !important;
    /* Crear un "agujero" en el backdrop */
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5),
        0 0 0 2px #ffffff,
        0 0 0 4px #007bff !important;
    pointer-events: auto !important;
    opacity: 1 !important;
}

/* Eliminar el backdrop original cuando se resalta una celda */
.tour-step-element+.tour-backdrop {
    display: none !important;
}

.jexcel_container.tour-step-element {
    z-index: 1051 !important;
    position: relative !important;
}


/* Estilos para el Pop Tour */
/* Estilos base para todos los botones del tour */
.tour .btn {
    padding: 3px 15px;
    border: none;
    border-radius: 5px;
    transition: all 0.2s ease;
    font-weight: 500;
    margin: 0 2px;
    font-size: 14px;
    /* Asegura el mismo tamaño de fuente */
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 60px;
    /* Asegura un ancho mínimo igual para todos */
}

/* Contenedor principal de botones */
.tour .popover-navigation {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 8px;
    width: 100%;
}

/* Grupo de botones anterior/siguiente */
.tour .btn-group {
    display: flex;
    gap: 8px;
    flex-shrink: 0;
}

/* Quitar márgenes adicionales */
.tour [data-role="end"],
.tour .btn-restart {
    margin-top: 0;
}

/* Ocultar el div adicional del footer */
.tour .tour-footer {
    display: none;
}

/* Mover el botón reiniciar al contenedor principal */
.tour .btn-restart {
    order: 3;
    /* Controla el orden de los botones */
}

/* Estilos específicos de cada botón */
.tour [data-role="prev"] {
    background-color: #e9ecef;
    color: #495057;
}

.tour [data-role="prev"]:hover {
    background-color: #dee2e6;
}

.tour [data-role="next"] {
    background-color: #5bae75;
    color: white;
}

.tour [data-role="next"]:hover {
    background-color: #399e59;
}

.tour [data-role="end"] {
    background-color: #dc3545;
    color: white;
}

.tour [data-role="end"]:hover {
    background-color: #c82333;
}

.tour .btn-restart {
    background-color: #007bff;
    color: white;
}

.tour .btn-restart:hover {
    background-color: #0056b3;
}