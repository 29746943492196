/* VisualStorytelling.css */
.visual-storytelling-container {
    padding: 6rem 0;
    background-color: #ffffff;
  }
  
  .storytelling-content {
    display: flex;
    gap: 4rem;
    align-items: center;
  }
  
  .storytelling-text {
    flex: 1;
    padding-right: 2rem;
  }
  
  .visual-title {
    font-size: 3.157rem !important;
    color: #2D2D3A;
    margin-bottom: 5rem;
    line-height: 1.2;
  }
  
  .visual-description {
    color: #666;
    margin-bottom: 2rem;
    line-height: 1.6;
    font-size: 1.333rem;
  }
  
  .features-list-key {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.333rem;
  }
  
  .feature-item {
    margin-bottom: 1rem;
  }
  
  .feature-icon {
    color: #00B37E;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    background-color: #E6F7EF;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 1;
  }
  
  .feature-icon svg {
    width: 20px;
    height: 20px;
  }

  .access-button-storyv {
    background-color: #02A724 !important;
    color: white !important;
    font-weight: bold;
    height: 56px !important;
    font-size: 1.333rem !important;
    padding: 0 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    transition: background-color 0.3s ease !important;
    cursor: pointer !important;
  }
  
  .access-button-storyv:hover {
    background-color: white !important;
    color: #02A724 !important;
  }

  .access-button-storyv svg {
    width: 20px;
    height: 20px;
  }

  .storytelling-image {
    flex: 1;
  }
  
  .dashboard-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
  
  @media (max-width: 768px) {
    .storytelling-content {
      flex-direction: column-reverse;
    }
  
    .storytelling-text {
      padding-right: 0;
    }
  }