/* PricingHero.css */
.custom-button-Pricinghero {
    background-color: #02A724 !important;
    color: white !important;
    font-weight: bold;
    height: 56px !important;
    font-size: 1.333rem !important;
    padding: 0 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    transition: background-color 0.3s ease !important;
  }
  
  .custom-button-Pricinghero:hover {
    background-color: white !important;
    color: #02A724 !important;
  }

  .custom-button-Pricinghero{
    cursor: pointer !important; /* !important para asegurarse, con precaución */
  }
  
  .custom-button-Pricinghero svg {
    width: 20px;
    height: 20px;
  }


  @media (max-width: 768px) {
    .custom-button-Pricinghero {
      height: 40px !important;
      font-size: 1rem !important;
      padding: 0 1.5rem !important;
    }
  }