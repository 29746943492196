.hero-background {
    background: linear-gradient(to bottom, #6FC3F7, #1813A2);
    padding: 4rem 0;
    width: 100%;
  }
  
  .hero-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    align-items: center;
    gap: 4rem;
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .hero-heading {
    color: white !important;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
    font-size: 4rem !important;
    line-height: 1.2 !important;
    margin-bottom: 2rem !important;
    font-weight: bold !important;
  }
  
  .hero-text {
    color: white !important;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    line-height: 1.6;
    margin-bottom: 2.5rem;
    font-size: 1.25rem !important;
  }
  
  .hero-button {
    background-color: #63b27b !important;
    color: white !important;
    padding: 0 2.5rem !important;
    height: 56px !important;
    font-size: 1.333rem !important;
    font-weight: bold !important;
    transition: all 0.3s ease !important;
    border: 2px solid #63b27b !important;
    border-radius: 8px !important;
    cursor: pointer !important;
  }
  
  .hero-button:hover {
    background-color: white !important;
    color: #63b27b !important;
    transform: translateY(-2px);
  }
  
  .hero-image-side {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  .hero-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
  
  @media (max-width: 768px) {
    .custom-button-how,
    .outline-button-how {
      width: 100% !important;
      height: 48px !important;
      font-size: 1.1rem !important;
      padding: 0 1.5rem !important;
    }
  
    .custom-button-how svg,
    .outline-button-how svg {
      width: 16px;
      height: 16px;
    }
  }