.templates-grid-container {
  background-color: #f0f4f8;
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
}

.templates-grid-content {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 4rem;
}

.templates-grid-heading {
  text-align: center;
  margin: 3rem 0;
  font-size: clamp(2rem, 5vw, 3.157rem);
  line-height: 1.2;
}

.templatesgrid-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.templatesgrid-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
}

.template-card {
  background-color: white;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.template-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #02A724;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.template-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.template-card:hover::before {
  transform: scaleX(1);
}

.template-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.template-card-title {
  font-size: clamp(1.4rem, 3vw, 1.777rem);
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.template-card-description {
  font-size: clamp(1rem, 2vw, 1.333rem);
  color: #666;
  flex-grow: 1;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.template-card-button-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.template-card-button {
  background-color: #02A724;
  font-size: clamp(1rem, 2vw, 1.333rem);
  color: white;
  padding: 0.8rem 2rem;
  border: 2px solid #02A724;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  max-width: 250px;
}

.template-card-button:hover {
  background-color: white;
  color: #02A724;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .templates-grid-content {
    padding: 0 0.5rem;
  }

  .templates-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .templates-grid-container {
    padding: 1rem 0.5rem;
  }

  .templates-grid-heading {
    margin: 2rem 0;
  }

  .template-card {
    padding: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .templates-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .template-card {
    padding: 1rem;
  }

  .template-card-button {
    padding: 0.7rem 1.5rem;
  }
}