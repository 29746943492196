.hero-container {
  width: 100%;
  overflow: hidden;
}

.hero-main-section {
  min-height: 100vh;
  padding: 20px;
}

@media (min-width: 768px) {
  .hero-main-section {
    min-height: 600px;
  }
}

.hero-container-inner {
  padding: 1rem;
  max-width: 100%;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

@media (min-width: 768px) {
  .hero-content {
    align-items: flex-start;
    text-align: left;
  }
}

.hero-subtitle {
  color: #4CAF50;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}

.landing-hero-title {
  color: white !important;
  font-size: 2rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  padding: 0 1rem;
  width: 100%;
  word-wrap: break-word;
}

.landing-hero-description {
  color: white;
  font-size: 1.2rem;
  line-height: 1.3 !important;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  width: 100%;
  word-wrap: break-word;
}

.hero-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
}

@media (min-width: 768px) {
  .hero-buttons {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.custom-button-hero,
.outline-button-hero {
  width: 100%;
  max-width: 280px;
  height: 44px !important;
  font-size: 1rem !important;
  padding: 0 1rem !important;
}

.hero-image-container {
  width: 100%;
  margin-top: 1rem;
}

.hero-background-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

/* Media Queries */
@media (min-width: 480px) {
  .hero-subtitle {
    font-size: 1.2rem;
  }

  .landing-hero-title {
    font-size: 2.5rem !important;
  }

  .landing-hero-description {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero-subtitle {
    font-size: 1.777rem;
  }

  .landing-hero-title {
    font-size: 4.209rem !important;
  }

  .landing-hero-description {
    font-size: 2.369rem;
  }

  .hero-background-image {
    height: 300px;
  }

  .custom-button-hero,
  .outline-button-hero {
    height: 56px !important;
    font-size: 1.333rem !important;
    padding: 0 2.5rem !important;
  }
}

/* Mantener los estilos de hover y transición existentes */
.custom-button-hero:hover {
  background-color: white !important;
  color: #02A724 !important;
}

.outline-button-hero:hover {
  background-color: white !important;
  color: #02A724 !important;
}

.custom-button-hero svg {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.custom-button-hero {
  background-color: #02A724 !important;
  color: white !important;
}

.outline-button-hero {
  background-color: #02A724 !important;
  border-color: white !important;
  color: white !important;
}