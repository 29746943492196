.template-page-wrapper {
  background: linear-gradient(to bottom, #000000, #003300);
  min-height: 100vh;
  color: white;
  width: 100%;
  overflow-x: hidden;
}

.template-page {
  padding: 4rem 1rem;
}

.template-content {
  max-width: 900px;
  margin-bottom: 2rem;
  text-align: center;
}

.template-subtitle {
  color: #59ad73;
  font-size: 1.777rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.template-title {
  font-size: 4.209rem !important;
  font-weight: bold;
  line-height: 5rem !important;
  color: white;
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-align: center;
  word-wrap: break-word;
}

.template-description {
  color: #cccccc;
  line-height: 1.6;
  font-size: 2.369rem;
}

.template-image-container {
  width: 100%;
  max-width: 1000px;
  height: auto;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 255, 0, 0.1);
  position: relative;
}

.template-hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.template-start-button {
  background-color: #02A724 !important;
  color: white !important;
  font-weight: bold;
  height: 56px !important;
  font-size: 1.333rem !important;
  padding: 0 2.5rem !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  transition: background-color 0.3s ease !important;
  cursor: pointer !important;
}

.template-start-button:hover {
  background-color: white !important;
  color: #02A724 !important;
}


.template-start-button svg {
  width: 20px;
  height: 20px;
}


.additional-components {
  margin-top: 4rem;
}




@media screen and (max-width: 768px) {
  .template-page {
    padding: 2rem 1rem;
  }

  .template-title {
    font-size: 2.5rem !important;
    line-height: 3rem !important;
    margin: 1rem 0;
  }

  .template-subtitle {
    font-size: 1.2rem;
  }

  .template-description {
    font-size: 1.5rem;
    padding: 0 1rem;
  }

  .template-image-container {
    margin: 0 1rem;
    width: calc(100% - 2rem);
  }

  .template-start-button {
    width: calc(100% - 2rem);
    margin: 0 1rem;
    padding: 0 1rem !important;
    font-size: 1rem !important;
  }
}

/* Media queries para dispositivos muy pequeños */
@media screen and (max-width: 480px) {
  .template-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .template-description {
    font-size: 1.2rem;
  }

  .template-start-button {
    height: 48px !important;
  }
}